<template>
  <div class="collapsible-panel" :class="{ opened: visible }">
    <div
      class="collapsible-panel-header d-flex flex-row align-items-center pointer pr-2"
      :class="[{ 'justify-content-between': wideHeader }, headerClass]"
      @click="visible = !visible"
    >
      <slot name="title">
        <span>
          {{ title }}
        </span>
      </slot>
      <i
        class="icon fas text-dark cc-toggle"
        :class="visible ? 'fa-chevron-up' : 'fa-chevron-down'"
      ></i>
    </div>
    <b-collapse
      class="collapsible-panel-body"
      v-model="visible"
      :class="bodyClass"
      :id="id || 'k-collapsible-panel-' + ct"
    >
      <slot v-if="visible"></slot>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
let ct = 0;
export default {
  name: 'collapsible-container',
  data() {
    return {
      visible: false,
      ct: (ct++).toString(),
    };
  },
  mounted() {},
  created() {
    this.visible = this.initialVisibility;
  },
  methods: {},
  computed: {},
  watch: {},
  props: {
    initialVisibility: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
    },
    headerClass: {
      type: String,
    },
    bodyClass: {
      type: String,
    },
    wideHeader: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped></style>
